<template>
    <div>
        <!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->
        <!-- Data Pimpinan -->
        <b-card>
            <div class="pl-4 py-2 border rounded shadow-sm">
                <b-button variant="primary" class="px-4" to="/user/wakil/list" pill>
                    <i class="fa fa-arrow-circle-left pr-2"></i> 
                    Kembali
                </b-button>
            </div>
            <div class="py-2">
                <b-table-simple bordered small responsive>
                    <b-th colspan="5">
                        <h5 class="text-center">Detail {{ items.jabatan }}</h5>
                    </b-th>
                    <b-tr>
                        <b-td width="150px">Nama</b-td>
                        <b-td width="300px" class="font-weight-bold">{{ items.nama }}</b-td>
                        <b-td width="150px">No./Tgl. Surat Ketetapan</b-td>
                        <b-td class="font-weight-bold text-center">{{ items.no_sk }}</b-td>
                        <b-td class="font-weight-bold text-center">{{ items.tgl_sk }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>NIK</b-td>
                        <b-td colspan="4" class="font-weight-bold">{{ items.nik }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Jabatan</b-td>
                        <b-td colspan="4">{{ items.jabatan }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Alamat</b-td>
                        <b-td colspan="4">
                            {{ items.alamat }},
                            Kel. {{ items.wilayah_kelurahan.nama }},
                            Kec. {{ items.wilayah_kelurahan.wilayah_kecamatan.nama }},
                            {{ items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.nama }},
                            {{ items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi.nama }},
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Kontak</b-td>
                        <b-td colspan="4">
                            No. Hp: {{ items.nomp }} <br>
                            No. Telepon: {{ items.notelepon }}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Email</b-td>
                        <b-td colspan="4">{{ items.email }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Status</b-td>
                        <b-td colspan="4">
                            <div v-if="items.aktif">
                                <span v-if="items.aktif === 1" class="label label-success">
                                    <span class="h6">Aktif</span>
                                </span>
                                <span v-else class="label label-danger">
                                    <span class="h6">Non Aktif</span>
                                </span>
                            </div>
                        </b-td>
                    </b-tr>
                    <!--  <b-tr>
                        <b-td colspan="5" class="py-2"></b-td>
                    </b-tr>
                    <b-th colspan="5">
                        <h5 class="text-center">Nomor Wajib Pajak Daerah &#40;NPWPD&#41;</h5>
                    </b-th>
                    <b-tr>
                        <b-td width="150px">Nama</b-td>
                        <b-td width="300px" class="font-weight-bold">{{ items.npwpd.nama }}</b-td>
                        <b-td>No./Tgl. Registrasi</b-td>
                        <b-td class="font-weight-bold text-center">{{ items.npwpd.no_registrasi }}</b-td>
                        <b-td class="font-weight-bold text-center">{{ items.npwpd.tgl_registrasi }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Nomor Induk</b-td>
                        <b-td colspan="4" class="font-weight-bold">{{ items.npwpd.no_induk }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Nomor Pokok</b-td>
                        <b-td colspan="4" class="font-weight-bold">{{ items.npwpd.no_pokok }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Alamat</b-td>
                        <b-td colspan="4">{{ items.npwpd.alamat }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Kontak</b-td>
                        <b-td colspan="4">
                            No. Hp: {{ items.npwpd.nomp }} <br>
                            No. Telepon: {{ items.npwpd.notelepon }}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Email</b-td>
                        <b-td colspan="4">{{ items.npwpd.email }}</b-td>
                    </b-tr> -->
                </b-table-simple>
            </div>
        </b-card>
        <!-- End Data Pimpinan -->
    </div>
</template>

<script>
import Logo from '@/assets/images/logoSumut.png'
import axios from 'axios'
import HeadTitle from '@/components/header/HRetribusi.vue'

export default {
    components: {
        HeadTitle
    },
    data () {
        return {
            imageLogo: Logo,
            items: null
        }
    },
    mounted () {
        axios.get('/api/wajib-pajak/npwpd-wakil/' + this.$route.params.id).then(response => {
            console.log(response.data)
            this.items = response.data.data
        }).catch(error => {
            console.log(error)
            if (error.response.error == 401) {
                localStorage.removeItem("EP_token")
                this.$router.push({name: "Login"})
            }
        })
    },
    methods: {
        editButton (index) {
            this.$router.push({name: 'EditWakilUser', params:{id: index}})
        }
    }
}
</script>